<template>
  <div>
    <div
        v-if="files.length === 0 || multiple"
        class="box-img-upload"
        :class="{ required: required }"
        @click="onPickFile"
        v-cloak
        @drop.prevent="addDropFile"
        @dragover.prevent
        ref="comp"
    >
      <div>
        <i class="las la-cloud-upload-alt"/>
        <h4>{{ text }}</h4>
        <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="image/*"
            @change="onFilePicked"
        />
      </div>
    </div>
    <p v-if="files.length === 0 && required" class="documentError">
      {{ reqText || `O campo ${text} é obrigatório` }}
    </p>
    <v-container>
      <h4 v-if="files.length > 0" class="titleDocument">{{ text }}</h4>
      <v-row v-if="files.length > 0">
        <v-col v-for="(file, index) in files" :key="index">
          <card-document
              :title="file.name"
              :path="file.path"
              @clickRemove="removeFile(index)"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardDocument from "@/components/DefaultComponents/CardDocument";

export default {
  name: "DefaultDocumentInput",
  props: {
    type: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    reqText: {
      type: String,
      default: true
    },
    module: {
      type: String,
      required: true,
    },
    text: {type: String, default: ""},
    multiple: {type: Boolean, default: false},
    initialValue: {
      type: Array,
      default: () => []
    }
  },
  components: {CardDocument},
  data() {
    return {
      file: false,
      fileName: "",
      images: [],
      files: [],
    };
  },
  methods: {
    removeFile(index) {
      if (this.files[index].id || this.files[index].id === 0) {
        // this.DELETE_DOCUMENT(this.files[index].id);
      }
      this.$emit("update:removeFile", this.files[index]);
      this.files = this.files.filter((item, key) => key !== index);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    async onFilePicked(event) {
      let file = event.target.files[0];
      await this.manipulateFile(file);
    },
    async addDropFile(event) {
      let file = Array.from(event.dataTransfer.files)[0];
      await this.manipulateFile(file);
    },
    async manipulateFile(file) {
      if (!this.multiple && this.files.length > 0) {
        return;
      }
      let base64 = await this.toBase64(file);
      this.file = true;
      this.fileName = file.name;
      this.files.push({
        name: file.name,
        path: null,
        base64: base64,
        type: this.type,
        module: this.module
      });
      this.$emit("update:returnFile", this.files);
    },
  },
  watch: {
    initialValue: {
      handler(value) {
        this.files = value
      }
    }
  },
  mounted() {
    this.files = this.initialValue
  }
};
</script>


<style scoped>
.box-img-upload {
  cursor: pointer;
  width: 100%;
  height: 150px;
  border-radius: 20px;
  border: 3px dashed #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.box-img-upload i {
  font-size: 90px;
  color: #3c2e88;
}

.box-img-upload h4 {
  color: #3c2e88;
}

.required h4 {
  color: #ff5252 !important;
  /* background-color: black; */
}

.required i {
  color: #ff5252 !important;
  /* background-color: black; */
}

.required {
  border: 3px dashed #ff5252 !important;
  /* background-color: black; */
}

.nowrap-overflow {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.titleDocument {
  color: #3c2e88;
}

.documentError {
  padding-top: 5px;
  padding-left: 20px;
  font-size: 12px;
  font-family: Roboto;
  color: #ff5252;
}
</style>
